//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import url('https://fonts.googleapis.com/css?family=Prompt');

@import "base/functions";
@import "base/mixins";
@import "components/mixins";
@import "vendors/plugins/mixins";

// Custom variables
@import "components/variables.custom";
@import "components/variables";
@import "components/variables-dark";

// Bootstrap initializaton
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// 3rd-Party plugins variables
@import "vendors/plugins/variables";
@import "vendors/plugins/variables-dark";

// Custom layout variables
@import "layout/base/variables";
@import "layout/variables.custom";